import {Component, ViewChild} from '@angular/core';
import {EnvService} from "../services/env.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {BaseComponent} from "../base/base.component";
import {ActivatedRoute, Router, Params } from '@angular/router';
import {AlertService} from "../services/alert.service";
import {AuthService} from "../services/auth.service";
import {SmChecksLogComponent} from "./smcs-log.component";
import {SmCheckDashboard} from "./sm-dashboard.component";

@Component({
  selector: 'micro-sm-detail',
  templateUrl: './sm-detail.component.html'
})
export class SmCheckDetailComponent extends BaseComponent {
  companyId:any;
  smCheck:any;
  testResult:any;
  nextExecutionTime:any;
  newCommandArg:any;
  newHttpHeader:any;
  commandArgs:any[] = [];
  httpHeaders:any[] = [];
  newTransformArg:any;
  transformArgs:any[] = [];
  testCommandResult:any;
  gettingTestCommandResult:boolean = false;
  testCheckResults:any[];
  gettingTestCheckResults:boolean = false;
  target:string = 'neg';
  neSelCommandTestServiceId:any;
  neSelResponseTestServiceId:any;
  specProb:any;
  webClientCommand: {
    method : "",
    urlPath: "",
    requestContentType: 'NONE',
    command: "",
    headers: {}
  };

  serviceType:any;
  transforms:any[];

  @ViewChild(SmChecksLogComponent) log: SmChecksLogComponent;
  @ViewChild(SmCheckDashboard) chartByDay: SmCheckDashboard;

  constructor(protected env: EnvService,
              protected http: HttpClient,
              private route: ActivatedRoute,
              private router:Router,
              private authService:AuthService,
              private alertService:AlertService) {
    super(env, http);
  }

  protected onInit(): void {
    this.http.get(`${this.env.e.url}/transform/transforms`).subscribe(
      data => {
        this.transforms = data as any[];
      }
    );

    this.resetNewCommandArg();
    this.resetNewHttpHeader();
    this.resetNewTransformArg();
    this.companyId = this.authService.getCompanyId();

    this.route.params
      .subscribe((params: Params) => {
        let id:any = params['id'];
        if (id === '0') {
          this.createNew();
        } else {
          this.reload(id);
        }
      });
  }

  onServiceTypeChange() {
    this.reloadServiceType();
  }

  reloadServiceType() {
    if (this.smCheck.serviceType) {
      this.http.post(`${this.env.e.url}/cmd/lookup/serviceTypes/${this.smCheck.serviceType}`, {}).subscribe(
        data => {
          this.serviceType = data;
          this.setExtFromCommand();
        }
      );
    }
  }

  public schedule(id) {
    this.http.post(`${this.env.e.url}/sm/${id}/schedule`, {}).subscribe(
      data => {
        this.reload(id);
      }
    );
  }

  public reload(id) {
    this.http.get(`${this.env.e.url}/sm/${id}`).subscribe(
      data => {
        this.setSmCheck(data);
        if (this.smCheck.company) {
          this.companyId = this.smCheck.company.id;
        }
        this.getNextExecutionTime();
        this.target = this.smCheck.negId ? 'neg' : this.smCheck.neId ? 'ne' : this.smCheck.serviceGroupId ? 'serviceGroup' : 'service';
      }
    );
    if (this.log) {
      this.log.reload();
    }
    if (this.chartByDay) {
      this.chartByDay.refreshData();
    }
  }

  createNew() {
    this.smCheck = {
      enabled: false,
      schedule: '0 */5 * * * *',
      transform: 'NONE',
      transformArgs: {},
      script:
        'alarm = alarming.newAlarm()\n' +
        'if (output == 1) {\n' +
        '\tstatus = \'SUCCESS\'\n' +
        '\talarm.severity = \'CLEARED\'\n' +
        ' \tlog.println(\'Got 1 SUCCESS!\')\n' +
        '} else {\n' +
        '\tstatus = \'ERROR\'\n' +
        '\talarm.severity = \'CRITICAL\'\n' +
        '\tlog.println("Expected 1 but got " + output)\n' +
        '}\n' +
        'alarming.send(alarm)'
      ,
      commandArgs: {},
      alarmNwType: "MICRO",
      alarmNe: "${ne}",
      alarmMoClass: "SERVICE_MONITOR",
      alarmMoInst: "${name}",
      alarmSpecProb: "SERVICE_MONITOR_CHECK_FAILED",
      alarmProbCause: "Service monitor check failed [${name}]",
      alarmRepActs: "See details for service monitor check [${name}]",
      alarmEvtType: "CHECK",
      alarmAddText: "",
      alarmSiteId: "",
      failureSeverity: "CRITICAL",
      timeout: 20000,
      smGroups: [],
      targetType: 'GROUP'
    };
    this.setExtFromCommand();
  }

  populateSmCheck() {
    if (this.companyId && this.companyId !== '') {
      this.smCheck.company = {
        id: this.companyId
      }
    }

    this.smCheck.commandArgs = {};
    for (let arg of this.commandArgs) {
      this.smCheck.commandArgs[arg.name] = arg.value;
    }

    this.smCheck.transformArgs = {};
    for (let arg of this.transformArgs) {
      this.smCheck.transformArgs[arg.name] = arg.value;
    }

    switch (this.target) {
      case 'neg':
        this.smCheck.neId = null;
        this.smCheck.serviceGroupId = null;
        this.smCheck.serviceId = null;
        break;
      case 'ne':
        this.smCheck.negId = null;
        this.smCheck.serviceGroupId = null;
        this.smCheck.serviceId = null;
        break;
      case 'serviceGroup':
        this.smCheck.neId = null;
        this.smCheck.negId = null;
        this.smCheck.serviceId = null;
        break;
      case 'service':
        this.smCheck.neId = null;
        this.smCheck.negId = null;
        this.smCheck.serviceGroupId = null;
        break;
    }

    this.setCommandFromExt();
  }

  save() {
    this.populateSmCheck();
    if (!this.smCheck.id) {
      this.http.post(`${this.env.e.url}/sm`, this.smCheck)
        .subscribe(
          data => {
            this.setSmCheck(data);
            this.getNextExecutionTime();
            this.alertService.info(`Created ${this.smCheck.name}`);
          }
        );
    } else {
      this.http.put(`${this.env.e.url}/sm/${this.smCheck.id}`, this.smCheck)
        .subscribe(
          data => {
            this.setSmCheck(data);
            this.getNextExecutionTime();
            this.alertService.info(`Updated ${this.smCheck.name}`);
          }
        );
    }
  }

  test() {
    let targetId:any;
    if (this.smCheck.targetType === 'GROUP') {
      targetId = this.neSelResponseTestServiceId;
    } else {
      targetId = this.smCheck.targetId;
    }
    let params:HttpParams = new HttpParams()
      .set("targetId", targetId || '')
      ;
    this.populateSmCheck();
    this.gettingTestCheckResults = true;

    this.http.post(`${this.env.e.url}/sm/test`, this.smCheck, {params:params})
      .subscribe(
        data => {

          var runChecks = data as any[];
          var busy = false;
          var rcIds: string[] = []
          for (const rc of runChecks) {
            rcIds.push(rc.id);
            busy = busy || rc.status === "PENDING";
          }

          if (busy) {
            setTimeout(() => this.checkTestResults(rcIds), 2000);
            return;
          }

          this.testCheckResults = runChecks;
          this.gettingTestCheckResults = false;
        }
      );
  }

  checkTestResults(runChecks: any[]) {
    this.http.post(`${this.env.e.url}/sm/checkTest`, runChecks)
      .subscribe(
        data => {
          var runChecks = data as any[];
          var busy = false;
          var rcIds: string[] = []
          for (const rc of runChecks) {
            rcIds.push(rc.id);
            busy = busy || rc.status === "PENDING";
          }

          if (busy) {
            setTimeout(() => this.checkTestResults(rcIds), 2000);
            return;
          }

          this.testCheckResults = runChecks;
          this.gettingTestCheckResults = false;
        }
      );
  }


  delete() {
    this.http.delete(`${this.env.e.url}/sm/${this.smCheck.id}`)
      .subscribe(
        complete => {
          this.alertService.info(`Deleted ${this.smCheck.name}`);
          this.router.navigate(["/smcs"]);
        }
      );
  }

  runCheck() {
    this.testResult = null;
    this.http.get(`${this.env.e.url}/sm/${this.smCheck.id}/test`).subscribe(
      data => {
        this.testResult = data as any;
      }
    );
  }

  getNextExecutionTime() {
    if (!this.smCheck.id) {
      this.nextExecutionTime = null;
      return;
    }
    this.http.get(`${this.env.e.url}/sm/${this.smCheck.id}/next`).subscribe(
      data => {
        this.nextExecutionTime = data as any;
      }
    );
  }

  hasRole(role:string) {
    if (!role) {
      return true;
    }
    return this.authService.hasRole(role);
  }

  resetNewCommandArg() {
    this.newCommandArg = {
      "name": "",
      "value": ""
    };
  }

  resetNewHttpHeader() {
    this.newHttpHeader = {
      "name": "",
      "value": ""
    };
  }


  resetNewTransformArg() {
    this.newTransformArg = {
      "name": "",
      "value": ""
    };
  }

  addTransformArg() {
    this.transformArgs.push({
      "name": this.newTransformArg.name,
      "value": this.newTransformArg.value
    });
    this.resetNewTransformArg();
  }

  addCommandArg() {
    this.commandArgs.push({
      "name": this.newCommandArg.name,
      "value": this.newCommandArg.value
    });
    this.resetNewCommandArg();
  }

  addHttpHeader() {
    this.httpHeaders.push({
      "name": this.newHttpHeader.name,
      "value": this.newHttpHeader.value
    });
    this.resetNewHttpHeader();
  }

  removeCommandArg(i) {
    this.commandArgs.splice(i, 1);
  }

  removeHttpHeader(i) {
    this.httpHeaders.splice(i, 1);
  }

  removeTransformArg(i) {
    this.transformArgs.splice(i, 1);
  }

  reloadSpecProb() {
    if (this.smCheck.alarmSpecProbId) {
      this.http.get(`${this.env.e.url}/domain/specProbs/${this.smCheck.alarmSpecProbId}`).subscribe(
        data => {
          this.specProb = data as any;
        }
      );
    }
  }

  setSmCheck(smCheck:any) {
    this.smCheck = smCheck;
    this.reloadServiceType();
    this.reloadSpecProb();
    this.commandArgs = [];
    this.transformArgs = [];
    Object.keys(smCheck.commandArgs).forEach((key) =>{
      this.commandArgs.push({
        "name": key,
        "value": smCheck.commandArgs[key]
      });
    });

    Object.keys(smCheck.transformArgs).forEach((key) =>{
      this.transformArgs.push({
        "name": key,
        "value": smCheck.transformArgs[key]
      });
    });
    this.setExtFromCommand();
  }

  testCommand() {
    this.testCommandResult = null;

    let targetId:any;
    if (this.smCheck.targetType === 'GROUP') {
      targetId = this.neSelCommandTestServiceId;
    } else {
      targetId = this.smCheck.targetId;
    }

    this.setCommandFromExt();

    let commandArgs = {};
    for (let arg of this.commandArgs) {
      commandArgs[arg.name] = arg.value;
    }

    let transformArgs = {};
    for (let arg of this.transformArgs) {
      transformArgs[arg.name] = arg.value;
    }


    let command = {
      "type": 'CmdReq',
      "serviceType": this.smCheck.serviceType,
      "targetId": targetId,
      "timeout": this.smCheck.timeout,
      "command": this.smCheck.command,
      "params": commandArgs,
      "transform": {
        "transform": this.smCheck.transform,
        "params": transformArgs
      }
    };

    if (this.smCheck.serviceType === 'HTTP') {
      command.type = "CmdWebClientReq";
    }


    this.gettingTestCommandResult = true;
    this.http.post(`${this.env.e.url}/cmd/exec`, command).subscribe(
      data => {
        this.testCommandResult = data;
        this.gettingTestCommandResult = false;
      }
    );
  }

  isObject(val):boolean {
    if (!val) {
      return false;
    }
    return typeof (val) === 'object';
  }

  isTestCommandResultObject():boolean {
    return typeof (this.testCommandResult.result) === 'object';
  }

  getCmdEditMode() {
    switch (this.smCheck.serviceType) {
      case 'SQL':
        return 'sql';
      case 'SSH':
        return 'sh';
      case 'HTTP':
        if (this.webClientCommand.requestContentType !== 'NONE') return this.webClientCommand.requestContentType;
        return 'text';
      default:
        return 'text';
    }
  }

  hasTransform() {
    switch (this.smCheck.serviceType) {
      case 'SSH':
        return true;
      case 'HTTP':
        return true;
      default:
        return false;
    }
  }

  onTransformChange() {
    console.log(this.smCheck.transform);
    if (!this.smCheck.transform || this.smCheck.transform === '') {
      this.transformArgs = [];
      return;
    }

    var t = null;
    for (let transform of this.transforms) {
      if (transform.name === this.smCheck.transform) {
        t = transform;
        break;
      }
    }

    if (!t) {
      this.transformArgs = [];
      return;
    }

    this.transformArgs = t.args;
  }

  public setExtFromCommand() {
    let newExt = undefined;
    if (this.smCheck.serviceType === 'HTTP') {
      try {
        newExt = JSON.parse(this.smCheck.command);
        if (newExt.requestContentType === 'NONE') newExt.command = "";
        this.httpHeaders = [];
        for (var key in newExt.headers) {
          if (newExt.headers.hasOwnProperty(key)) {
            var val = newExt.headers[key];

            if (val instanceof Array) {
              if (val.length) {
                for (let i = 0; i < val.length; i++) {
                  this.httpHeaders.push({
                    "name": key,
                    "value": val[i]
                  });
                }
              } else {
                // in case of an valueless header
                this.httpHeaders.push({
                  "name": key,
                  "value": undefined
                });
              }
            } else {
              this.httpHeaders.push({
                "name": key,
                "value": val
              });
            }
          }
        }
      } catch (e) {
        newExt = {
          method : "GET",
          urlPath: "/",
          requestContentType: 'NONE',
          command: "",
          headers: {}
        }
      }
    }
    this.webClientCommand = newExt;
  }
  private setCommandFromExt() {
    if (this.smCheck.serviceType === 'HTTP') {
      this.webClientCommand.headers = {};
      for (let arg of this.httpHeaders) {
        let xHdr = this.webClientCommand.headers[arg.name];
        if (xHdr) {
          // already exists, add to value array
          xHdr.push(arg.value);
        } else {
          // new header
          this.webClientCommand.headers[arg.name] = [arg.value];
        }
      }
      if (this.webClientCommand.requestContentType === 'NONE') this.webClientCommand.command="";
      this.smCheck.command = JSON.stringify(this.webClientCommand, null, 2);
    }
  }
}
